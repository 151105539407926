import { json, redirect } from "@remix-run/node";
import { useLoaderData, useSearchParams } from "@remix-run/react";
import { fetchJobPosts } from "~/utils/fetch_job_posts";
import "~/styles/index.scss";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { BodyDiv } from "~/components/typography";
import { fetchBoard } from "~/utils/fetch_board";
import { fetchFeaturedPosts } from "~/utils/fetch_featured_posts";
import { fetchDepartments } from "~/utils/fetch_departments";
import { fetchOffices } from "~/utils/fetch_offices";
import { Logo } from "~/components/logo";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import BoardContent, { MAX_PER_PAGE } from "~/components/board_content";
import useRecruitics from "~/hooks/use_recruitics";
import { withNamespace } from "~/utils/translation";
import { fetchBoardConfiguration } from "~/utils/fetch_board_configuration";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { fetchCustomFields } from "~/utils/fetch_custom_fields";
import { customFieldFiltersFromUrl } from "~/utils/custom_field_filters";
import { fetchRecentlyLiveDepartments } from "~/utils/fetch_recently_live_departments";
import { createBoardOpenGraphTags } from "~/utils/build_page_metadata";
import classNames from "classnames";
import { Header } from "~/components/header";
import { ErrorFlash } from "~/components/flash";
import { JobAlert } from "~/components/job_alert";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const t = withNamespace("board");

  return data
    ? [
        {
          title: t("page_title", {
            companyName: data.board?.name,
            interpolation: { escapeValue: false },
          }),
        },
        ...createBoardOpenGraphTags(data.board, data.url),
      ]
    : [];
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const urlToken = params.url_token as string;

  const departmentIds = url.searchParams.getAll("departments[]") || undefined;
  const officeIds = url.searchParams.getAll("offices[]") || undefined;
  const keyword = url.searchParams.get("keyword") || undefined;
  const customFieldFilters = customFieldFiltersFromUrl(url);

  const { configuration, status } = await fetchBoardConfiguration({ urlToken });

  if (status === 404 || configuration === undefined) {
    throw new Response(null, { status: 404 });
  }

  const { display_department_hierarchy: displayDepartmentHierarchy, display_departments } =
    configuration;

  const [
    { jobPosts, status: jobStatus },
    { featuredPosts },
    { board, status: boardStatus },
    { departments, status: departmentsStatus },
    { departments: recentlyLiveDepartments, status: recentlyLiveDepartmentsStatus },
    { offices, status: officesStatus },
    { customFields, status: customFieldsStatus },
  ] = await Promise.all([
    fetchJobPosts({
      urlToken,
      content: true,
      page: url.searchParams.get("page") || undefined,
      count: MAX_PER_PAGE,
      departmentIds,
      officeIds,
      keyword,
      includeDepartment: true,
      customFieldFilters,
      orderByDepartment: display_departments,
    }),
    fetchFeaturedPosts({
      urlToken,
    }),
    fetchBoard({ urlToken }),
    fetchDepartments({ urlToken, displayDepartmentHierarchy }),
    fetchRecentlyLiveDepartments({ urlToken }),
    fetchOffices({ urlToken }),
    fetchCustomFields({ urlToken }),
  ]);

  if (boardStatus === 404) {
    throw new Response(null, { status: 404 });
  }

  if (board.redirect_to) {
    return redirect(board.redirect_to);
  }

  if (
    jobStatus === 404 ||
    departmentsStatus === 404 ||
    recentlyLiveDepartmentsStatus === 404 ||
    officesStatus === 404 ||
    customFieldsStatus === 404
  ) {
    throw new Response(null, { status: 404 });
  }

  return json({
    jobPosts,
    featuredPosts,
    board,
    departments,
    recentlyLiveDepartments,
    offices,
    departmentIds,
    officeIds,
    customFieldFilters,
    keyword,
    urlToken,
    customFields,
    url: request.url,
  });
};

export const handle = { i18n: ["board", "job_post"] };

export default function Index() {
  const {
    jobPosts,
    featuredPosts,
    board,
    departments,
    recentlyLiveDepartments,
    offices,
    departmentIds,
    officeIds,
    customFieldFilters,
    keyword,
    urlToken,
    customFields,
  } = useLoaderData<typeof loader>();
  const {
    board_layout: boardLayoutConfiguration,
    allow_external_notifications: allowExternalNotifications,
    logo: logoConfiguration,
    filters: filterConfiguration,
    outside_label: outsideLabel,
    enable_recruitics: enableRecruitics,
    header_text: headerText,
  } = useBoardConfiguration();
  const { withTrackingParams } = useTrackingParams();
  const [searchParams] = useSearchParams();

  const commonT = withNamespace("common");
  const error = searchParams.get("error");

  const departmentFiltered = departmentIds && departmentIds.length > 0;
  const officeFiltered = officeIds && officeIds.length > 0;
  const customFieldFiltered =
    customFieldFilters && Object.values(customFieldFilters).some((values) => values.length > 0);
  const filtered =
    officeFiltered || departmentFiltered || customFieldFiltered || !!keyword || jobPosts.page !== 1;
  const showExternalNotifications =
    allowExternalNotifications && recentlyLiveDepartments.length > 0;

  useRecruitics(enableRecruitics);

  return (
    <div className="index--content">
      {error === "true" && <ErrorFlash message={commonT("errors.job_post_inactive")} />}
      <Logo
        url={logoConfiguration.url}
        href={logoConfiguration.href && withTrackingParams(logoConfiguration.href)}
        companyName={board.name}
      />
      <Header headerText={headerText} companyName={board.name} />
      <div
        className={classNames("index--header", { "index--header__no-description": !board.content })}
      >
        {board.content && (
          <div className="index--description">
            <BodyDiv>
              <span dangerouslySetInnerHTML={{ __html: board.content }}></span>
            </BodyDiv>
          </div>
        )}
      </div>
      {showExternalNotifications && <JobAlert urlToken={urlToken} companyName={board.name} />}

      <BoardContent
        departments={departments}
        offices={offices}
        customFields={customFields}
        departmentIds={departmentIds}
        officeIds={officeIds}
        customFieldFilters={customFieldFilters}
        keyword={keyword}
        boardLayoutConfiguration={boardLayoutConfiguration}
        internalBoard={false}
        filterConfiguration={filterConfiguration}
        filtered={filtered}
        jobPosts={jobPosts}
        featuredPosts={featuredPosts}
        outsideLabel={outsideLabel}
      />
    </div>
  );
}
